<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="card shadow mb-7">
    <div class="card-body pt-6 pb-0">
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="nav nav-stretch text-center nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
        >
          <li class="nav-item">
            <router-link
              to="/area-gestionale/users/utenti-societa"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Società
            </router-link>
          </li>
          <li v-if="isEnabled('fnGestioneUtenzePersone')" class="nav-item">
            <router-link
              to="/area-gestionale/users/utenti-persone"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Persone
            </router-link>
          </li>
          <li v-if="isEnabled('fnGestioneUtenzeComitati')" class="nav-item">
            <router-link
              to="/area-gestionale/users/utenti-comitati"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Comitati
            </router-link>
          </li>
          <li v-if="isEnabled('fnGestioneUtenzeOmologatori')" class="nav-item">
            <router-link
              to="/area-gestionale/users/omologatori"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Omologatori
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <router-view
    @bloccaUtente="bloccaUtente"
    @sbloccaUtente="sbloccaUtente"
    @resetPsw="resetPsw"
    :refresh="refresh"
  ></router-view>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { alertFailed, alertSuccess } from "@/composables/swAlert";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import isEnabled from "@/composables/isEnabled.js";

import {
  bloccaUtenza,
  sbloccaUtenza,
  resetPassword,
} from "@/requests/utenzeRequests";

export default defineComponent({
  name: "utenti-abilitate",
  components: { Loading },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Utenze abilitate", []);
    });

    const isLoading = ref(false);
    const refresh = ref(false);

    const bloccaUtente = (adb2c_guid) => {
      Swal.fire({
        title: "Sei Sicuro?",
        html: "L'utenza verrà bloccata. <br> L'utente NON potrà più accedere al Management-FITP.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Blocca",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await bloccaUtenza(adb2c_guid).then((res) => {
            if (res.status == 200) {
              alertSuccess("Utenza bloccata correttamente");
              refresh.value = true;
              setTimeout(() => {
                refresh.value = false;
              }, 1500);
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const sbloccaUtente = (adb2c_guid) => {
      Swal.fire({
        title: "Sei Sicuro?",
        html: "L'utenza verrà sbloccata. <br> L'utente potrà nuovamente accedere al Management-FITP.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Sblocca",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await sbloccaUtenza(adb2c_guid).then((res) => {
            if (res.status == 200) {
              alertSuccess("Utenza sbloccata correttamente");
              refresh.value = true;
              setTimeout(() => {
                refresh.value = false;
              }, 1500);
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const resetPsw = (adb2c_guid) => {
      Swal.fire({
        title: "Sei Sicuro?",
        html: "Procedendo verrà resettata la password di accesso al management FITP.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Reset password",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await resetPassword(adb2c_guid).then((res) => {
            if (res.status == 200) {
              alertSuccess("Password resettata correttamente");
              refresh.value = true;
              setTimeout(() => {
                refresh.value = false;
              }, 1500);
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    return {
      bloccaUtente,
      sbloccaUtente,
      resetPsw,
      isLoading,
      refresh,
      isEnabled,
    };
  },
});
</script>
