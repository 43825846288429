import services from "../axios/dbManag";

export const bloccaUtenza = (adb2c_guid) => {
  return services.dbManag
    .post("/management/user/lock", {
      adb2c_guid,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const sbloccaUtenza = (adb2c_guid) => {
  return services.dbManag
    .post("/management/user/unlock", {
      adb2c_guid,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addUtenza = (
  name,
  mail,
  password,
  id_comitato,
  id_societa,
  id_persona,
  id_utente,
  cod_utenza
) => {
  return services.dbManag
    .post("/management/user/create", {
      name,
      mail,
      password,
      id_comitato,
      id_societa,
      id_persona,
      id_utente,
      cod_utenza,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const resetPassword = (objectId) => {
  return services.dbManag
    .post("/management/user/password/reset", {
      objectId,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addUtenzaAbilitata = (
  username,
  password,
  mail,
  id_comitato,
  id_societa,
  id_persona,
  id_omologatore,
  id_utente
) => {
  return services.dbManag
    .post("/sistema/comitato/utenti/add", {
      username,
      password,
      mail,
      id_comitato,
      id_societa,
      id_persona,
      id_omologatore,
      id_utente,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
